// i18next-extract-mark-ns-start andorra-payment-gateway

//Payment methods
import { Background } from "components/Background";
import { Button } from "components/Button";
import { ContactSalesButton } from "components/ContactSalesButton";
import { Content } from "components/Content";
import { IndexBackground } from "components/landings/IndexBackground";
import { List } from "components/List";
import { Partners } from "components/Partners";
import { PaymentMethods } from "components/PaymentMethods";
import { Section, SectionActions, SectionHeader } from "components/Section";
import { SEO } from "components/SEO";
import { SignUpButton } from "components/SignUpButton";
import { graphql } from "gatsby";
import { Link, Trans, useI18next } from "gatsby-plugin-react-i18next";
import { links } from "global.config";
import chat from "images/chat.svg";

//Images
import credit_andorra_monei from "images/credit_andorra_monei.svg";

//Partners
import React from "react";
import styled from "styled-components";
import { IndexImage } from "components/landings/IndexImage";
import { BlogLink } from "components/links/Blog";
import { InternalPageLink } from "components/links/Pages";

const IndexContent = styled.div`
  padding-top: 0;
  padding-bottom: 0;
  max-width: 500px;
`;

const IndexSection = styled(Section)`
  padding-top: 80px;
  padding-bottom: 0;
  @media (max-width: 1440px) {
    padding-top: 10%;
    padding-bottom: 0;
  }
  @media (max-width: 1024px) {
    padding-top: 10%;
    padding-bottom: 0;
  }
  @media (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 80px;
  }
`;

const BackgroundSection = styled(Section)`
  @media (max-width: 768px) {
    padding-top: 180px;
    padding-bottom: 80px;
  }
  @media (max-width: 425px) {
    padding-top: 100px;
    padding-bottom: 80px;
  }
`;

const TextSection = styled(Section)`
  text-align: center;
  font-size: 1.2rem;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    padding-top: 100px;
    padding-bottom: 80px;
  }
`;

const Page: React.FC = () => {
  const {navigate, t} = useI18next();
  const title = t('Andorra Payment Gateway');
  const description = t(
    `MONEI’s exclusive agreement with Creand Crèdit Andorrà, the leading bank in the Andorran financial market, brings digital payments to e-commerce businesses based in Andorra.`
  );
  return (
    <>
      <IndexBackground sx={{left: {xl: "50%"}}}>
        <Content>
          <SEO title={title} description={description} />
          <IndexSection reverseOnMobile>
            <IndexContent>
              <SectionHeader underline tagName="h1" style={{fontSize: '2rem'}}>
                <Trans>Andorra Payment Gateway</Trans>
              </SectionHeader>
              <Trans parent="p">
                MONEI’s exclusive agreement with Creand Crèdit Andorrà, the leading bank in the Andorran
                financial market, brings digital payments to e-commerce businesses based in Andorra.
              </Trans>
              <List>
                <Trans parent="li">Accept all major online payment methods</Trans>
                <Trans parent="li">
                  Easy integration with your e-commerce platform or custom website
                </Trans>
                <Trans parent="li">Enjoy short settlement lead times, get paid in 1 day</Trans>
                <Trans parent="li">Get real-time sales updates via your customer dashboard</Trans>
              </List>
              <a href="#benefits" style={{textDecoration: 'none'}}>
                <Trans>More benefits of working with MONEI and Creand Crèdit Andorrà</Trans>
              </a>
              <SectionActions align="left">
                <SignUpButton variant="light">
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage src={credit_andorra_monei} alt="MONEI payment gateway" title="MONEI payment gateway" />
          </IndexSection>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <BackgroundSection centered id="benefits">
            <div style={{maxWidth: 800}}>
              <SectionHeader underline>
                <Trans>The Benefits of Working with MONEI and Creand Crèdit Andorrà</Trans>
              </SectionHeader>
              <Trans parent="p">
                MONEI’s unified <Link to="/">payment gateway solution</Link> is the choice of many
                European based e-commerce companies.
              </Trans>
              <Trans parent="p">
                Our serverless architecture allows you to scale and process a high volume of
                transactions. Safely and securely accept digital payments and benefit from dynamic
                pricing — as you sell more, your transaction fees decrease.
              </Trans>
              <Trans parent="p">
                In partnership with Creand Crèdit Andorrà, the main player in the Andorran Market providing
                digital solutions, we bring you a payments platform that suits your e-commerce
                business needs.
              </Trans>
              <List>
                <Trans parent="li">
                  Keep your payments safe and secure, MONEI is{' '}
                  <BlogLink slug="psd2">
                    PSD2 and SCA compliant
                  </BlogLink>
                </Trans>
                <Trans parent="li">
                  Increase sales conversions with Dynamic 3D Secure (the verification screen pops up
                  directly on your checkout page without redirecting shoppers)
                </Trans>
                <Trans parent="li">
                  Tokenization lets your customers save their payment method for future purchases
                </Trans>
                <Trans parent="li">
                  Use <Link to="/features/pay-by-link/">Pay By Link</Link> to create manual payments
                  and send invoices via email
                </Trans>
                <Trans parent="li">
                  Quickly and easily set up{' '}
                  <InternalPageLink slug="features/recurring-payments">recurring payments</InternalPageLink>
                </Trans>
                <Trans parent="li">
                  View information about each payment, including location and device with your
                  customer database
                </Trans>
              </List>
              <Trans parent="p">
                Creand Crèdit Andorrà is committed to serving the country with its innovative technology,
                global banking, and highly qualified team of professionals.
              </Trans>
              <SectionActions align="center">
                <SignUpButton variant="dark">
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </BackgroundSection>
        </Content>
      </Background>
      <Content fullWidth>
        <Section textAlign="center">
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>Accept all major payment methods</Trans>
            </SectionHeader>
            <Trans parent="p">
              Work with MONEI and Creand Crèdit Andorrà to offer your customers their preferred payment
              methods
            </Trans>
            <PaymentMethods andorraPage />
            <Button variant="light" onClick={() => navigate('/payment-methods')}>
              <Trans>Vew all payment methods</Trans>
            </Button>
          </div>
        </Section>
      </Content>
      <Content fullWidth>
        <Section textAlign="center">
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>Your e-commerce platform? Yes!</Trans>
            </SectionHeader>
            <Partners />
          </div>
        </Section>
      </Content>
      <Content>
        <TextSection>
          <div style={{maxWidth: 800}}>
            <Trans parent="p">
              <b>Creand Crèdit Andorrà</b> has global recognition with over 60 years of dedication to
              banking and a long track record defined by its devotion to service. It’s the main
              financial group in Andorra and is paving the way to become an international leader in
              the field of wealth management.
            </Trans>
            <Trans parent="p">
              Our partnership with Creand Crèdit Andorrà lets you simplify your digital payments, sell
              internationally, and offer your customers many payment methods within one single
              platform.
            </Trans>
            <Trans parent="p">
              Plug-in MONEI’s payment gateway with popular e-commerce platforms such as{' '}
              <Link to="/shopify-payment-gateway/">Shopify</Link>,
              <Link to="/woocommerce-payment-gateway/">WooCommerce</Link>, and{' '}
              <Link to="/wix-payment-gateway/">Wix</Link>. If you have a custom e-commerce website,
              develop your custom integration using{' '}
              <a href={links.api} target="_blank" rel="noopener noreferrer">
                our API
              </a>{' '}
              and{' '}
              <a href={links.docs} target="_blank" rel="noopener noreferrer">
                dev documentation
              </a>
              .
            </Trans>
          </div>
        </TextSection>
        <Section centered>
          <div style={{maxWidth: 800}}>
            <div>
              <img src={chat} alt="MONEI payment gateway"/>
            </div>
            <SectionHeader underline>
              <Trans>How Can I Get Started?</Trans>
            </SectionHeader>
            <Trans parent="p">
              Whether you're an existing Creand Crèdit Andorrà customer that's ready to get started with
              e-commerce, an Andorran business that wants to use MONEI’s payment gateway solution,
              we’re here to help.
            </Trans>
            <SectionActions align="left">
              <SignUpButton variant="light">
                <Trans>Open an Account</Trans>
              </SignUpButton>
              <ContactSalesButton />
            </SectionActions>
          </div>
        </Section>
      </Content>
    </>
  );
};

export default Page;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "andorra-payment-gateway"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
